<template>
    <div>
        <!-- CAROUSEL-->
    <!-- <b-row>
      <b-col cols="12" >
        <div>
          <b-carousel indicators :interval="3000" controls >
            <b-carousel-slide     
                v-if="resources!=null"           
                :img-src="resources.img_header_principal"
            />
          </b-carousel>
        </div>
      </b-col>
    </b-row> -->

        <br>
        <!-- <h1 class="titles">
          
          <span style="color: #d82125">G</span>
          <span style="color: #d02175">o</span>
          <span style="color: #f6c921">b</span>
          <span style="color: #d02175">i</span>
          <span style="color: #4d944a">e</span>
          <span style="color: #07aaba">r</span>
          <span style="color: #d82125">n</span>
          <span style="color: #d02175">o</span>
          
        </h1> -->

        <h1 class="titles">
          <span style="color: #8b8c8c">Temazcales</span>
        </h1>
<br><br>

    <b-row  align-h="center" class="mt-4" v-for="(item, idx) in datos " :key="idx">
      <b-col cols="12" sm="10" md="5" lg="3"  class="mb-3">
          <b-container  style="cursor:pointer;">
             <b-img :src="'https://www.teotihuacan.gob.mx/turismot/4%20temazcal/'+item.img+'.jpeg'" fluid rounded class="c-shadow-sm" ></b-img>
          </b-container>
        </b-col>
        <b-col cols="10" sm="8" md="5" lg="4">
          <b-row><h5><b>{{item.nombre}}</b></h5></b-row>
          <b-row><h6><b-icon icon="geo-fill"/> {{item.direccion}}</h6></b-row>
          <b-row><h6><b-icon icon="telephone-fill"/> {{item.telefono}}</h6></b-row>
        </b-col>
    </b-row>

   

    </div>
</template>
<script>
  export default {
    components: {
    },
    mounted: function(){
     
    },
    computed: {
      
    },
    data() {
      return {
        resources:null,
        datos:[
          //{img:'1',nombre:' Temazcal Quetzalcoatl',direccion:'Carretera Piramides km. 1.6 Barrio de Purificacion Teotihuacán Estado de México ',telefono:'5946882119  Whatsapp:5524389372',url:'https://mx.latinoplaces.com/mexico/temazcal-quetzalcoatl-798216'},
//{img:'99',nombre:'Temazcalli Zyanya',direccion:'Avenida Vicente Guerrero s/n Col. Centro San Juan Teotihuacán Estado de México',telefono:'5512983567',url:'**'},
//{img:'99',nombre:'Temazcal Axochitl',direccion:'Calle Ariza esquina con calle primera de Ochupango San Lorenzo Tlalmimilolpan Teotihuacán Estado de México',telefono:'5523405607   5949563797',url:'**'},
//{img:'99',nombre:' Jardin de Cactaceas ',direccion:'Carretera a piramides esquina camino real a Tuxpan s/n Purificacion Teotihuacán Estado de México',telefono:'5540952288   5549945806',url:'**'},
//{img:'99',nombre:' Temazcal Casa de Barro ',direccion:'Cerretera México - Tulancingo Km.24 Teotihuaca Estado de México ',telefono:'5524099954',url:'Facebook/temazcalcasadebarro'},
//{img:'99',nombre:' Temazcal Posasa colibrí ',direccion:'Prol,Av. Miguel Hidalgo 37, 55816 San Juan Teotihuacán Estado de México',telefono:'5949332025',url:'http://posadacolibri.com'},
//{img:'99',nombre:' Temazcal Tecoamictahtli ',direccion:'Av. Constitución #24 San Sebastián Xolalpa Teotihuacán Estado de México',telefono:'5537376837',url:'**'},
//{img:'99',nombre:' Temazcal Ixachi colli temazcalli',direccion:'Av. Vicente Guerrero s/n San Juan Teotihuacán col. Centro ',telefono:'5534074878',url:'https://es-la.facebook.com/izachicollitemazcalli/'},
{img:'99',nombre:'Hotel & Temazcal Telpochcalli',direccion:'Calle constitución 8 San Sebastián Xolalpa Teotihuacán',telefono:'5941016301   5535412784   5519127569',url:'https://nguide.ar-living360.mx/Hoteles/H-Ptelpochcalli.html'},
//{img:'99',nombre:' Tlalocan',direccion:' Av. Pirámides, Purificacion, 55810 San Juan Teotihuacan de Arista, Méx.',telefono:'55 1188 3006',url:'https://es-la.facebook.com/TemazcalyparquetematicoTlalocan'},
//{img:'99',nombre:' Temazkal Teotl',direccion:' Jorge Jimenez Cantu S/N Cozotlan, 55810 San Juan Teotihuacan de Arista, Méx.',telefono:' 55 3492 6658',url:''},
//{img:'99',nombre:' Temazcal Atetelco',direccion:' Juárez 9, Purificacion, 55810 San Juan Teotihuacan de Arista, Méx.',telefono:'55 2778 5614',url:'https://atetelco1.wixsite.com/temazcal'},
//{img:'99',nombre:'Temazcal Renacer',direccion:'Cale Juárez #10, Purificacion, 55810 San Juan Teotihuacan de Arista, Méx.',telefono:'',url:'https://www.facebook.com/pg/Temazcal-Spa-Renacer-1360523890739409/posts/'},
//{img:'99',nombre:' Prehispa, Spa & Temazcal en Teotihuacan',direccion:'Calle Fresno. 2 Cozotlan, Norte, 55800 San Juan Teotihuacan de Arista, Méx.',telefono:'55 1586 2101',url:'https://es-la.facebook.com/prehispa/'},
//{img:'99',nombre:' Temazcal Tlaxinca',direccion:'',telefono:'5517527793',url:''},
//{img:'99',nombre:' Meztli',direccion:'',telefono:'5532758996',url:''},
{img:'99',nombre:'Temazcal de la media luna ',direccion:'Unnamed Road, Militar, Santa María Cozotlán, Méx.',telefono:'55 3413 9844',url:'https://m.facebook.com/Temazcal-de-la-media-luna-1841642006149997/'},
//{img:'99',nombre:' Xochitl Calli',direccion:'Tlajinga, Junto a la morena San Lorenzo Tlalmimilolpan San Juan Teotihuacán',telefono:'3121329722    5540136696',url:'Facebbock: Temazcal Xochitl Calli'},
{img:'99',nombre:' Temazcal Hotel Fer',direccion:'C. La palma, int.2 San Francisco Mazapa Teotihuacán Estado de México',telefono:'5511944228   5632673338',url:'https://hotel-fer.negocio.site/?utm_source=gmb&utm_medium=referral'},
{img:'99',nombre:'Temazcal Hotel Villas Teotihuacán ',direccion:'Periférico sur s/n 55800 San Sebastián Xolalpa Teotihuacán Estado de México',telefono:'5949560244   5558369020',url:'http://www.villasteo.com/index'},
//{img:'99',nombre:'Temazcal Hotel Ollin Teotl',direccion:'Atetelco #14 Purificación San juan Teotihuacán Estado de México ',telefono:'5949560390   5522572454',url:'Facebook@ollin1809   ollin_1809@hotmail.com'},


        ],
      }
    }
    
  }
</script>